<template>
	<div class="activity-dashboard">
		<v-container fluid>
			<v-row>
				<v-col cols="12">
					<ActivityFeed :key="key" layout="blank" />
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { onBeforeUnmount, ref } from '@vue/composition-api';
import ActivityFeed from './streamer/home/ActivityFeed.vue';

export default {
	components: {
		ActivityFeed,
	},
	setup() {
		const key = ref(new Date().getTime());

		// Handle WS events.
		const eventBus = require('js-event-bus')();
		eventBus.on('ws-message', function (payload) {
			if (
				payload.method == MessageEnum.BITS_TRANSACTION ||
				payload.method == MessageEnum.TTS_APPROVAL_EXPIRED ||
				payload.method == MessageEnum.TTS_PENDING_APPROVAL
			) {
				key.value = new Date().getTime();
			}
		});

		onBeforeUnmount(() => {
			eventBus.detachAll();
		});

		return { key };
	},
};
</script>
